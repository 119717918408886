import { appConfig } from "@/config/appConfig";
import { createHttp } from "@/api/HttpHelper";
import { createMJHttp } from "@/api/MJRequest";

const axios = createHttp(appConfig.base2GUrl);
const SystemAxios = createMJHttp(appConfig.SystemBaseUrl);
const RiskAxios = createMJHttp(appConfig.YWBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);

// 车辆信息
export const getDeviceInfo = (data) => {
  return axios.axios({
    url: "/vehicle/getDeviceInfo",
    method: "get",
    loading: true,
    params: data,
  });
};

// 车辆工时信息-列表
export const getWorkHourDetail = (data) => {
  return statisticsAxios.axios({
    url: "/oilReport/getWorkHourDetail",
    method: "get",
    loading: true,
    params: data,
  });
};

// 车辆工时信息-图表
export const workingSum = (data) => {
  return statisticsAxios.axios({
    url: "/oilReport/workingSum",
    method: "get",
    loading: true,
    params: data,
  });
};

// 车辆工时信息-列表-导出
export const exportWorkHourDetail = (data) => {
  return statisticsAxios.axios({
    url: "/oilReport/exportWorkHourDetail",
    method: "get",
    responseType: "blob",
    timeout: 1800000,
    fileName: '工时统计报表',
    loading: true,
    params: data,
  });
};

// 车辆报警信息 - 报警类型
export const getAlarmConf = (data) => {
  return statisticsAxios.axios({
    url: "/safetyManage/getAlarmConf",
    method: "get",
    params: data,
  });
};

// 车辆报警信息-列表
export const SCAlramList = (data) => {
  return statisticsAxios.axios({
    url: "/safetyManage/SCAlramList",
    method: "get",
    loading: true,
    params: data,
  });
};

// 车辆报警信息-导出
export const exportSCAlramList = (data) => {
  return statisticsAxios.axios({
    url: "/safetyManage/exportSCAlramList",
    method: "get",
    responseType: "blob",
    timeout: 1800000,
    fileName: '工时统计报表',
    loading: true,
    params: data,
  });
};

// 车辆基本信息
export const getDeviceBasicInInfo = (data) => {
  return axios.axios({
    url: "/vehicle/getDeviceInfo",
    method: "get",
    loading: true,
    params: data,
  });
};

// 车辆告警-详情
export const getPCZKAlarmDetail = (data) => {
  return statisticsAxios.axios({
    url: "/safetyManage/getPCZKAlarmDetail",
    method: "get",
    loading: true,
    params: data,
  });
};

// 车辆轨迹告警
export const getPCZKTrackAlarm = (data) => {
  return statisticsAxios.axios({
    url: "/safetyManage/getPCZKTrackAlarm",
    method: "get",
    loading: true,
    params: data,
  });
};

// 车辆保单信息
export const getDevicePolicy = (data) => {
  return axios.axios({
    url: "/vehicle/getDevicePolicy",
    method: "get",
    loading: true,
    params: data,
  });
};

// 车辆案件信息
export const getDeviceClaims = (data) => {
  return axios.axios({
    url: "/vehicle/getDeviceClaims",
    method: "get",
    loading: true,
    params: data,
  });
};

// 跟车记录
export const carFollowingExport = (data) => {
  return statisticsAxios.axios({
    url: "/basic-risk/car/carFollowingExport",
    method: "get",
    loading: true,
    params: data,
  });
};

// // 车辆报警信息-导出
// export const exportSCAlramList = (data) => {
//   return statisticsAxios.axios({
//     url: "/safetyManage/exportSCAlramList",
//     method: "get",
//     responseType: "blob",
//     timeout: 1800000,
//     fileName: '工时统计报表',
//     loading: true,
//     params: data,
//   });
// };

// 风控数据
export const getRiskByVehicle = (data) => {
  return RiskAxios.axios({
    url: "/basic-risk/riskManage/byVehicle",
    method: "post",
    loading: true,
    data,
  });
};

// 跟车记录
export const getVehicleDetails = (data) => {
  return axios.axios({
    url: "/vehicle/v2/details",
    method: "get",
    loading: true,
    params: data,
  });
};

// 安装记录 删除
export const terminalRecordDelete = (data) => {
  return axios.axios({
    url: "/date/terminal/record/delete",
    method: "get",
    loading: true,
    params: data,
  });
};

// 安装记录
export const terminalRecord = (data) => {
  return axios.axios({
    url: "/date/terminal/record",
    method: "get",
    loading: true,
    params: data,
  });
};

